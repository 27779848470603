<script>
	// export let drawn;
	export let classes = '';
	export let color = `#00dd25`;
</script>

<svg
	style="--check-color: {color}"
	class="checkmark {classes || 'h-4 w-4'}"
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 52 52"
>
	<circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
	<path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
</svg>

<!-- 
<svg
	class="{drawn ? 'drawn' : ''} {classes || 'h-4 w-4'}"
	version="1.1"
	id="tick"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 37 37"
	style="enable-background:new 0 0 37 37;"
	xml:space="preserve"
>
	<path
		shape-rendering="auto"
		class="circ path"
		style="fill:none;stroke:#fff;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;"
		d="
	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
	/>
	<polyline
		class="tick path"
		style="fill:none;stroke:#fff;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;"
		points="
	11.6,20 15.9,24.2 26.4,13.8 "
	/>
</svg>

<style>
	body {
		width: 50%;
		max-width: 250px;
		margin: 0 auto;
	}
	.circ {
		opacity: 0;
		stroke-dasharray: 130;
		stroke-dashoffset: 130;
		-webkit-transition: all 1.2s cubic-bezier(0.1, 0, 0.4, 1);
		-moz-transition: all 1.2s cubic-bezier(0.1, 0, 0.4, 1);
		-ms-transition: all 1.2s cubic-bezier(0.1, 0, 0.4, 1);
		-o-transition: all 1.2s cubic-bezier(0.1, 0, 0.4, 1);
		transition: all 1.2s cubic-bezier(0.1, 0, 0.4, 1);
	}
	.tick {
		stroke-dasharray: 50;
		stroke-dashoffset: 50;
		-webkit-transition: stroke-dashoffset 1s 0.5s ease-out;
		-moz-transition: stroke-dashoffset 1s 0.5s ease-out;
		-ms-transition: stroke-dashoffset 1s 0.5s ease-out;
		-o-transition: stroke-dashoffset 1s 0.5s ease-out;
		transition: stroke-dashoffset 1s 0.5s ease-out;
	}
	svg.drawn .path {
		opacity: 1;
		stroke-dashoffset: 0;
	}
</style> -->
<style>
	.checkmark__circle {
		stroke-dasharray: 166;
		stroke-dashoffset: 166;
		stroke-width: 2;
		stroke-miterlimit: 10;
		stroke: var(--check-color);
		fill: none;
		animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	}
	.checkmark {
		border-radius: 50%;
		display: block;
		stroke-width: 4;
		stroke: #fff;
		stroke-miterlimit: 10;
		box-shadow: inset 0px 0px 0px var(--check-color);
		animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
	}
	.checkmark__check {
		transform-origin: 50% 50%;
		stroke-dasharray: 48;
		stroke-dashoffset: 48;
		animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	}
	@keyframes stroke {
		100% {
			stroke-dashoffset: 0;
		}
	}
	@keyframes scale {
		0%,
		100% {
			transform: none;
		}
		50% {
			transform: scale3d(1.1, 1.1, 1);
		}
	}
	@keyframes fill {
		100% {
			box-shadow: inset 0px 0px 0px 30px var(--check-color);
		}
	}
</style>
